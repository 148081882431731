.signIn {
    float: center;
    align-items: center;
    color: black;
}

label {
    color: black
}

.logo {
    width: 9rem;
    cursor: pointer;

    @media (max-width: 600px) {
        width: 7rem;
    }
}
.verify{
    width: 100%!important;
    font-size: 1rem!important;
    color: white!important;
    background-color: #4caf50!important;
    &:disabled{
        color: #bcd75f!important;
        opacity: 0.5!important;
        cursor: not-allowed!important;
    }
}