.overlay {
  position: absolute;
  height: 150px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  display: none;
}
.container {
  position: relative;
  margin: 4px;
  width: 150px;
  height: auto;

  &:hover {
    .overlay {
      display: flex;
    }
  }
}
img,
video {
  border: 1px solid #e6e8eb;
}
.addIcon {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border: 1px dashed gray;
}
.singleImage {
  margin: 4px;
  border: 1px solid #e6e8eb;
}
