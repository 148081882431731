.overflowAuto {
  overflow: auto;
}
.filterCarbody {
  margin: 0 2rem 2rem;
  border: 1px solid lightgray;
  border-width: thin;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.filterLabel {
  font-size: 0.7rem !important;
  /* margin-top: 1rem; */
  margin-bottom: 0;
}
.customSearch {
  margin-bottom: 1rem;
}
